// import React from 'react';
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'animate.css';


export const Cuentas = () => {
  const [data, setData] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('http://localhost:4000/back.php');
        const response = await fetch('https://banco.perzosoft.com/back.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
        
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    const Allempresas = async () => {
      try {
        // const response = await fetch('http://localhost:4000/back.php');
        const response = await fetch('https://banco.perzosoft.com/empresas.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setEmpresa(jsonData);
      } catch (error) {
        setError(error.message);
      
      }
    };

    fetchData();
    Allempresas();
  }, []); // El segundo parámetro [] indica que este efecto se ejecutará solo una vez al montar el componente

  const borrar = (dato) => {
    
    const borrarData = async () => {

      const clave = dato.CLAVE;
       const formData = new FormData();
         formData.append('clave', clave);
 
       try {
         // const response = await fetch('http://localhost:4000/empresas.php');
         const response = await fetch('https://banco.perzosoft.com/cuentaborrar.php',{
           method: "POST",
           body: formData
         });
         if (!response.ok) {
           throw new Error('No se pudo obtener los datos');
         }
         
         const jsonData = await response.json();
               const respuesta = jsonData;
 
               // console.log(respuesta);
 
               if ("dato" in respuesta) {
                                    
           if (respuesta.icono === "success") {
 
         let borrarCuenta =  data.filter(cuenta => cuenta.CLAVE !== respuesta.dato.CLAVE); 
       
         setData(borrarCuenta);
         // console.log(borrarEmpresa);
                   } 
                 }
                   MySwal.fire({
                     icon: respuesta.icono,
                     title: respuesta.mensaje,
                     showClass: {
                       popup: `
                         animate__animated
                         animate__fadeInUp
                         animate__faster
                       `
                     },
                     showConfirmButton: false,
                     showCancelButton: false,
                     timer: 1500
                   });
               
       } catch (error) {
         console.log(error);
       }
     };
    
    const MySwal = withReactContent(Swal);


    MySwal.fire({
     title: "¿Quieres Elimiar la Cuenta " + dato.CLAVE + "?",
     showClass: {
       popup: `
         animate__animated
         animate__fadeInUp
         animate__faster
       `
     },
     text: "No podras revertir esta accion!",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Borrar!"
   }).then((result) => {
     if (result.isConfirmed) {
       borrarData();
     }
   });
    }

  const modificar = (dato, agrega,empresas) => {
    
   
    let modal;
    let titulo;
    let url;

    if (agrega) {
    url = "https://banco.perzosoft.com/cuentaInsert.php";
  titulo = `Nueva Cuenta`;
  modal = `
  <form>
 <div class="mb-3">
   <label class="form-label">Empresa  <span class="btn btn-primary btn-sm ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg><select id='buscar' class="btn btn-primary btn-sm ms-2"> 
<option value="">Selecciona</option>
 </select></span></label>
   <input id='div' class="form-control" type='tel' name='empresa' value=''/>
   <div id="inempresa"></div> 
   </div>
   <div class="mb-3">
   <label class="form-label">Clave</label>
   <input id='clave' class="form-control" type='text' name='clave' value='' maxlength="3" />   
   </div>
   <div class="mb-3">
   <label class="form-label">Cuenta</label>
   <input id='cuenta' class="form-control" type='text' name='cuenta' value=''/>   
   </div>
   <div class="mb-3">
   <label class="form-label">Banco</label>
   <input id='banco' class="form-control" type='text' name='banco' value=''/>    
   </div>
   <div class="mb-3">
   <label class="form-label">Usuario</label>
   <input id='usuario' class="form-control" type='text' name='usuario' value=''/>    
   </div>
</form>
 `;
} else {
  url = "https://banco.perzosoft.com/cuentaUpdate.php";
  titulo = `Clave: ${dato.CLAVE}`;
  modal = `
  <form>
 <div class="mb-3">
   <label class="form-label">Empresa  <span class="btn btn-primary btn-sm ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg><select id='buscar' class="btn btn-primary btn-sm ms-2"> 
<option value="">Selecciona</option>
 </select></span></label>
   <input id='div' class="form-control" type='tel' name='empresa' value='${dato.EMPRESA}'/>
   <div id="inempresa"></div> 
   </div>
   <div class="mb-3">
   <label class="form-label">Cuenta</label>
   <input id='cuentam' class="form-control" type='text' name='cuenta' value='${dato.CUENTA}'/>   
   </div>
   <div class="mb-3">
   <label class="form-label">Banco</label>
   <input id='banco' class="form-control" type='text' name='banco' value='${dato.BANCO}'/>    
   </div>
   <div class="mb-3">
   <label class="form-label">Usuario</label>
   <input id='usuario' class="form-control" type='text' name='usuario' value='${dato.USUARIO}'/>    
   </div>
</form>
 `;
 
}


    const MySwal = withReactContent(Swal);

     MySwal.fire({
      title: titulo,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      html: modal,
      focusConfirm: false,
      showCancelButton: true,
    }).then((result) => {
       
      if (result.isConfirmed) {        
      
        let clave;
        if(agrega){
           clave = document.querySelector("#clave").value;
        }else{
           clave = dato.CLAVE;
        }
        
         const empresacl = document.querySelector("#div").value;         
         const cuenta = document.querySelector("#cuentam").value;
         const banco = document.querySelector("#banco").value;
         const usuario = document.querySelector("#usuario").value;
         const tabla = document.querySelector("#tabla");

        // console.log(cuenta);
        //  return;
        const formData = new FormData();
        formData.append('clave', clave);
        formData.append('banco', banco);
      formData.append('empresa', empresacl);
      formData.append('cuenta', cuenta);
      formData.append('usuario', usuario);
          const EnviarData = async () => {

            try {
              // const response = await fetch('http://localhost:4000/empresas.php');
              const response = await fetch(url,{
                method: "POST",
                body: formData
            });
              if (!response.ok) {
                throw new Error('No se pudo obtener los datos');
              }
              const jsonData = await response.json();
              const respuesta = jsonData;

              // console.log(respuesta);

              if ("dato" in respuesta) {
                 
                if (agrega) {
                  
                  if (respuesta.icono === "success") {
                  
                    let tr = document.createElement("tr");
                    tr.id = respuesta.dato.CLAVE;
                  tr.innerHTML = `<th id='clave${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false, ${empresa})">${respuesta.dato.CLAVE}</th>
                  <td id='empresa${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false, ${empresa})">${respuesta.dato.EMPRESA}</td>
                  <td id='cuenta${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false, ${empresa})">${respuesta.dato.CUENTA}</td>
                  <td id='banco${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false, ${empresa})">${respuesta.dato.BANCO}</td>
                  <td id='usuario${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false, ${empresa})">${respuesta.dato.USUARIO}</td>
                  <td ><button onClick="borrar(${respuesta.dato})" type="button" class="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
        </svg></button></td>
                  `;
                  
        tabla.appendChild(tr);
        data.push({CLAVE: respuesta.dato.CLAVE, NOMBRE: respuesta.dato.NOMBRE}); 
        
                  } 
                  //  MySwal.fire({
                  //   icon: respuesta.icono,
                  //   title: respuesta.mensaje,
                  //   showClass: {
                  //     popup: `
                  //       animate__animated
                  //       animate__fadeInUp
                  //       animate__faster
                  //     `
                  //   },
                  //   showConfirmButton: false,
                  //   showCancelButton: false,
                  //   timer: 1500
                  // });
                } else {
                  
                  
                  if (respuesta.icono === "success") {
                  
                    data.forEach(element => {
                      
                      if (element.CLAVE === respuesta.dato.CLAVE) {
                        const Cambiarempresa = document.querySelector(`#empresa${respuesta.dato.CLAVE}`);
                        const Cambiarcuenta = document.querySelector(`#cuenta${respuesta.dato.CLAVE}`);
                        const Cambiarbanco = document.querySelector(`#banco${respuesta.dato.CLAVE}`);
                        const Cambiarusuario = document.querySelector(`#usuario${respuesta.dato.CLAVE}`);
                        // console.log(Cambiarcuenta);
                        Cambiarempresa.innerHTML = respuesta.dato.EMPRESA;
                        Cambiarcuenta.innerHTML = respuesta.dato.CUENTA;
                        Cambiarbanco.innerHTML = respuesta.dato.BANCO;
                        Cambiarusuario.innerHTML = respuesta.dato.USUARIO;
                        element.EMPRESA = respuesta.dato.EMPRESA;
                        element.CUENTA = respuesta.dato.CUENTA;
                        element.BANCO = respuesta.dato.BANCO;
                        element.USUARIO = respuesta.dato.USUARIO;
                      }
                    });
                    
                  } 

                  // MySwal.fire({
                  //   icon: respuesta.icono,
                  //   title: respuesta.mensaje,
                  //   showClass: {
                  //     popup: `
                  //       animate__animated
                  //       animate__fadeInUp
                  //       animate__faster
                  //     `
                  //   },
                  //   showConfirmButton: false,
                  //   showCancelButton: false,
                  //   timer: 1500
                  // });
                }
                
                
               }

               MySwal.fire({
                icon: respuesta.icono,
                title: respuesta.mensaje,
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500
              });
              
            } catch (error) {
             console.log(error.message); 
            }
          };

          EnviarData();
      }

      });
    
    

    const input = document.querySelector("#inempresa");
    const div = document.querySelector("#div");
    const buscar = document.querySelector("#buscar");

    empresas.forEach(Element => {
     const opcion = document.createElement("option");
      opcion.value = Element.CLAVE;
      opcion.innerHTML = Element.NOMBRE;
      buscar.appendChild(opcion);

      // <option value="volvo">Volvo</option>
     });
   
    const validation = () =>{

      const fetchData = async () => {
        
        ReactDOM.render(                  
          <div className="spinner-border mt-2" role="status"></div>,
          input
        ); 

        
      try {
      const response = await fetch('https://banco.perzosoft.com/empresasbus.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(div.value)
   
      });
            if (!response.ok) {
              throw new Error('No se pudo obtener los datos');
            }
            const jsonData = await response.json();

            
              if (jsonData === null || jsonData === "") {

                ReactDOM.render(                  
                  <p class='mt-2 fw-bold text-danger'>No se encontro ninguna empresa</p>,
                  input
                );
              
              } else {
                
                ReactDOM.render(                  
                  <p class='mt-2 fw-bold text-primary' >{jsonData[0].NOMBRE}</p>,
                  input
                );
              }
            
            
          } catch (error) {
            setError(error.message);
          }

          
        };

        fetchData();
      }
    
      div.addEventListener("input", validation);
      buscar.addEventListener("change", function (e) {
        let selectedOption = this.options[buscar.selectedIndex];

        
        ReactDOM.render(                  
          <p class='mt-2 fw-bold text-primary' >{selectedOption.text}</p>,
          input
        );

        div.value = e.target.value;

        // console.log(e.target.value);
        // console.log(selectedOption.text);
      });

      validation();
  }

  // data.forEach (element => {
  //   let i = 0;
  //   if (element.CUENTA =="" || element.CUENTA == undefined) {
  //     element.CUENTA = i;
  //   }
  //   i++
  // });
  if (loading) {
    // Muestra la animación de carga y el logo mientras se están cargando los datos
    return (    
      <div className="App-body">
    
    <section className= "seccion">
    <h1>Cuentas</h1>

    <div className="justify-content-center align-items-center">
      
    <br/>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    </section>
    </div>
      
    );
  }
  if (error) return <p>Error: {error}</p>;
  if (!data) return null; 
 
  return (
    <div className="App-body">
   
    <section className= "seccion ">
    <h1>Cuentas</h1>
    
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button onClick={() => modificar(null, true, empresa)} type="button" className="btn btn-primary Seleccionar_ok mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
</svg></button>
</div>

    <table className="table table-striped table-hover">
  <thead>
    <tr>
      <th scope="col">CLAVE</th>
      <th scope="col">EMPRESA</th>
      <th scope="col">CUENTA</th>
      <th scope="col">BANCO</th>
      <th scope="col">USUARIO</th>
      <th scope="col">ACCIONES</th>
    </tr>
  </thead>
  <tbody id="tabla">
  {data.map(item => (
  
          <tr>
          <th id={`clave${item.CLAVE}`} scope="row" onClick={() => modificar(item, false, empresa)} >{item.CLAVE}</th>
          <td id={`empresa${item.CLAVE}`} onClick={() => modificar(item, false, empresa)} >{item.EMPRESA}</td>
          <td id={`cuenta${item.CLAVE}`} onClick={() => modificar(item, false, empresa)} >{item.CUENTA}</td>
          <td id={`banco${item.CLAVE}`} onClick={() => modificar(item, false, empresa)} >{item.BANCO}</td>
          <td id={`usuario${item.CLAVE}`} onClick={() => modificar(item, false, empresa)} >{item.USUARIO}</td>
          <td>
            {/* <button onClick={() => modificar(item, false, empresa)}  type="button" className="btn btn-warning"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
  <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z"/>
</svg></button>  */}
<button onClick={() => borrar(item)} type="button" className="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
</svg></button> </td>
        </tr>
       
        ))}
    
  </tbody>
</table>

<div>
{/* <form>
  <div className='GrupoForm2'>
    <fieldset>
    <label>Clave: 001</label>
    <input className="clave" type='hidden' name='clave' value={1}/>
    </fieldset>

    <fieldset>
    <label>Empresa: 001 </label>
    <input className="" type='number' name='empresa' />
    </fieldset>

    <fieldset>
    <label>Cuenta: </label>
    <input className="" type='number' name='cuenta' />
    </fieldset>

    <fieldset>
    <label>Banco: </label>
    <input className="" type='text' name='banco' />
    </fieldset>

    <fieldset className='descripcion'>
    <label>Descripcion </label>
    <textarea className="textareadescripcion" type='number' name='descripcion' rows={8} cols="40"/>
    </fieldset>
    </div>
</form> */}
</div>
    </section>
    </div>
  )
}

